body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    margin-left: auto;
    margin-right: auto;
    color: #818e95;
    font-size:18px;
}

#contenu{
    margin: auto;
}

#ui-datepicker-div{
    font-size:13px;
}

#contenu table{
    width:100%;
}

#contenu table td{
    width:59%;
}

#contenu table th{
    font-size: 24px;
    text-align: left;
    padding: 10px 0 5px 20px;
}

#contenu table td input{
    width:100%;
    padding:3px;
}

#contenu table td textarea{
    width:100%;
    height:150px;
    padding:3px;
}

#contenu table td.label_formulaire {
    width:25%;
    padding-top: 4px;
    vertical-align: top;
    color: #818E95;
    text-align: right;
}

.decallage32{
    margin-left:32px !important;
}

.button{
    background: url(../images/fleche-blanche-bouton.png) no-repeat 6px center #53B139;
    background: url(../images/fleche-blanche-bouton.png) no-repeat 6px center, linear-gradient(to top, #59be3e, #4da535);
    box-shadow: inset 1px 1px 0 #85ff5b;
    border:1px solid rgba(0,0,0,.18);
    text-shadow:1px 1px 0 rgba(33,56,25, .4);
    color:#fff;
    font-family: "leaguegothic", Arial, sans-serif;
    font-size: 23px;
    padding: 6px 3px 4px 20px;
    letter-spacing: 1.2px;
    border-radius:3px;
    display: inline-block;
    margin-bottom: 20px;
    width: auto;
    cursor:pointer;
    font-size:16px;
}

.button:active, .button:hover{
    background: url(../images/fleche-blanche-bouton.png) no-repeat 6px center #57BA3C;
    background: url(../images/fleche-blanche-bouton.png) no-repeat 6px center, linear-gradient(to top, #4da535, #59be3e);
    text-shadow:1px 1px 0 rgba(33,56,25, .7);
}

.nouveau{
    float:right;
}

.checkboxes{
    padding-bottom: 20px;
}
.checkboxes input{
    width:auto !important;
}
#itroom_adminbundle_utilisateurtype_tousMagasins,
#itroom_adminbundle_opctype_tousMagasins,
.checkboxes ul{
    margin: 0 5px;
}

.checkboxes ul{
    list-style-type: none;
}
.checkboxes label{
    display: inline;
}
.non-valide{
    background-color: #FFC0C0;
}

#itroom_adminbundle_utilisateurtype_tousMagasins, #itroom_adminbundle_opctype_tousMagasins{
    margin-top:5px;
}

#formStat [type=submit]{
    margin-top:14px
}


/************************************************************/
.liste{
    width: 100%;
}

.liste th{
    background-color: #5B8CA8;
    color: #FFF;
    font-size:16px;
    margin: 3px;
    padding: 2px 0;
    border-right: 2px solid white;
}

.liste tr{

}

.liste td{
    font-size:14px;
    padding:2px 0 2px 8px;
    border-bottom: 1px solid #AAA;

}

.show tr:nth-child(even) {background: #ECECEC}
.show tr:nth-child(odd) {background: #FFF}

#site{
    width: 1000px;
    margin: 0 auto;
}
#header{
    height: 108px;
    background-image: url(/assets/images/logo-Boulsign.png);
    background-repeat:no-repeat;
    background-position:left 10px;
    background-size: 50%;
    margin-bottom: 20px;
    margin-top: 20px;
}

#infoconnexion{
    border: 1px solid #A4ACB0;
    margin-left:150px;
    position: absolute;
    font-size: 12px;
    padding: 3px 10px;
    background-color: #F7F9F9;
    color: #709AB2;

}

.clear{
    display: block;
    clear: both;
    height: 1px;
    line-height: 1px;
    font-size: 1px;
}

#navigation{
    border-top: 1px solid #A4ACB0;
    border-bottom: 1px solid #A4ACB0;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 30px;
}

.navigationlink {
    position: relative;
    width: 930px;
    margin-top:0px;
    padding-top:0px;
    padding-left:0;
    float: left;
}

.navigationlink li {
    float: left;
    list-style: none;
}

.navigationlink li.back {
    width: 9px; height: 30px;
    z-index: 8;
    position: absolute;
}
.navigationlink li.back .left {
    height: 30px;
    margin-right: 9px; /* 7px is the width of the rounded shape */
}

.navigationlink li a {
    text-decoration: none;
    outline: none;
    text-align: center;
    top: 2px;
    text-transform: uppercase;
    letter-spacing: 0;
    z-index: 10;
    display: block;
    float: left;
    position: relative;
    overflow: hidden;
    margin: 4px 10px;
}

.navigationlink li a:hover, .lavaLampWithImage li a:active, .lavaLampWithImage li a:visited {
    border: none;
}

#navigationl{
    width:97%;
    float:left;
    margin-top:5px;
}

#deconnexion{
    width:3%;
    float:left;
    margin-top:6px;
}

#infostatut{
    font-size: 14px;
}

a {
    color: #3C736B;
    text-decoration: none;
}

a:hover{
    text-decoration: underline;
}

#logo-enseigne{
    position: relative;
}

#headerenseigne{
    text-align: right;
    vertical-align: bottom;
    font-family: 'Fugaz One', cursive;
    font-size:30px;
    color: #709AB2;
}

#gestion{
    text-align: left;
    margin-right: 5px;
    margin-bottom: 5px;
}

#gestion a{
    font-size:13px;
    text-decoration: none;
    color: #A4ACB0;

}

#titre{
    color: #A4ACB0;
    font-size:14px;

}

#bouton_sauvegarder {
    background-color: #1F5575;
    color: #FFFFFF;
    font-weight: bold;
    width: 150px;
}

.form input[type=submit]{
    width:150px;
    height: 30px;
    cursor: pointer;
    border:1px solid white;
    margin-left: 393px;
    background-color: #1F5575;
    color: #FFFFFF;
    font-weight: bold;
    width: 150px;
}

.formulaire {
    width: 800px;
    margin: 10px 270px;
}

.formulaire textarea{
    background: none repeat scroll 0 0 #E2E6D7;
    border: 1px solid #FFFFFF;
    color: #777777;
    font-weight: bold;
    padding: 3px;
    height: 229px;
    width: 489px;
}

.formulaire select {
    background: none repeat scroll 0 0 #E2E6D7;
    border: 1px solid #FFFFFF;
    color: #777777;
    font-weight: bold;
    padding: 3px;
    width: 496px;
}

.formulaire input {
    background: none repeat scroll 0 0 #E2E6D7;
    border: 1px solid #FFFFFF;
    color: #777777;
    font-weight: bold;
    padding: 3px;
    width: 489px;
}

.formulaire input[type=submit] {
    background: #1F5575;
    color: #FFFFFF;
    font-weight: bold;
    width: 150px;
    margin-left: 96px;
}

.logo-admin {
    float: right;
    margin-right: 38px;
    width: 162px;
}

#tableau_variables {
    text-align: center;
    color: black;
}

.bouton_modifier{
    margin-left: 94%;
}

#exemple_revolving {
    color: black;
    font-weight: bold;
}

#exemple_revolving textarea {
    width: 100%;
    height: 150px;
}

.exemple_revolving {
    color: black;
    font-weight: bold;
    border:1px solid #AAA;
    margin:10px 0px;
    padding:5px;
}

.exemple_revolving textarea {
    width: 100%;
    height: 150px;
}

/**** style des flashbags ***/
.message-flash{
    background: #a9dc59;
    color: #444;
    padding: 8px 5px 8px 20px;
    border: 1px solid #819c3a;
    border-radius:4px;
    margin-bottom: 25px;
}

.message-flash-warning{
    background: #FAA22F;
    color: #872717;
    padding: 8px 5px 8px 20px;
    border: 1px solid #9E5900;
    border-radius:4px;
    margin-bottom: 25px;
}

#user_container_magasins{
    transition: opacity 0.2s ease-in-out 0s;
}

#connexion {
    border: 1px solid #DDDDDD;
    border-radius: 6px 6px 6px 6px;
    box-shadow: 2px 2px 10px #CCCCCC;
    margin: 200px auto 0;
    width: 460px;
    background-color: #f5f5f5;
}

#connexion form {
    margin-bottom: 0;
    padding-top: 15px;
}

#saisieNumeroMagasin {
    border: 1px solid #DDDDDD;
    border-radius: 6px 6px 6px 6px;
    box-shadow: 2px 2px 10px #CCCCCC;
    margin: 200px auto 0;
    width: 460px;
    background-color: #f5f5f5;
}

#saisieNumeroMagasin form {
    margin-bottom: 0;
    padding-top: 15px;
}

.control-group {
    padding: 0 20px;
    margin-bottom: 10px;
}

input.big {
    font-size: 25px;
    height: 35px;
    width: 95%
}

label {
    color: black;
    font-size: 14px;
}
table label {
    margin-right:15px;
}

.form-actions {
    margin-bottom: 0;
}

.navbar .nav > li > a {
    color: #777777;
    font-weight: bolder;
    float: none;
    font-size: 10px;
    padding: 10px 4px;
    text-decoration: none;
    text-shadow: 0 1px 0 #FFFFFF;
}

.mt30 {
    margin-top: 30px;
}

h1 {
    font-size: 25px;
}

.table-bordered th, .table-bordered td {
    font-size: 14px;
}

.table th, .table td {
    text-align: center;
}


.span_forcer_joker{
    cursor: pointer;
    color: #3C736B;
    float: none;
    margin-left: 0px;
}

.span_forcer_joker:hover{
    text-decoration: underline;
    color: #005580;
}

.table tr td {
    text-align: left;
}

.table tr td.action{
    text-align: center;
}

.hideOpcByMagasin {
    display: none;
}

.hideOpcByMedia {
    display: none;
}

.hideOpcByGamme {
    display: none;
}

.form-horizontal input, .form-horizontal textarea,
#contenu table td input, #contenu table td select, #contenu table td textarea{
    width: 90%;
}

input, select, textarea,
textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"]{
    border-color:rgba(82, 168, 236, 0.6);
}

button[type=submit]{
    width:20%; float: right; margin-right: 8%
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
    background-color: #EDF6F9;
}

#footer{
    margin-top: 150px;
}

input[type=radio]{
    margin:0 5px 0 0;
}

tbody td.media{
    padding:0;
}

td.media ul, td.media ul li{
    list-style: none;
    padding: 0;
    margin: 0;
}

td.media ul{
    padding:4px 5px;
}
td.media ul.dynamique{
    overflow: hidden;
    background: url(../images/plus.gif) no-repeat 95% 10px;
}

td.media ul.dynamique:hover{
    background-position: -2000px -2000px;
}

thead tr{
    background:#F5F5F5;
}

.date_debut, .date_fin{
    width:80px;
}


.list-group {
    padding-left: 0;
    margin-bottom: 20px;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    background-color: #ffffff;
    border: 1px solid #aaa;
    margin-bottom:15px;
}

.list-group-item:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.list-group-item > .badge {
    float: right;
}

.list-group-item > .badge + .badge {
    margin-right: 5px;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
    color: #ffffff;
    background-color: #428bca;
    border-color: #428bca;
    border-bottom:1px solid #A6CDEA;
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading {
    color: inherit;
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
    color: #e1edf7;
}

.list-group-item-heading {
    margin-top: 0;
    margin-bottom: 5px;
}

.list-group-item-text {
    margin-bottom: 0;
    line-height: 1.3;
}

.joker .infos td{
    padding: 0 20px 0 0;
}

.joker{
    font-size:13px;
}

.joker label{
    display: inline;
    margin: 0 10px 0 0;
    color:#818E95;
}

.joker.active label{
    display:none;
}

.joker.active input[disabled]{
    background: transparent;
    border:0;
    color:#fff;
    font-size:17px;
    font-weight:bold;
    box-shadow:0 0 0 transparent;
    display: inline;
    cursor:text;
    margin: 0;
    padding: 0;
    width:auto;
    vertical-align: bottom;
    position:relative;
    top:2px;
}
.joker.active input.date_debut, .joker.active input.date_fin{
    width:100px;
    margin:0 20px;
}

.joker.active{
    line-height: normal;
}

.joker.active .infos td{
    padding: 0 20px 0 0;
}

.joker input[type=button]{
    position:relative;
    top:-6px;
}
.joker.active input[type=button]{
    top:0;
}

.joker.active tr, .joker.active td{
    vertical-align: bottom;
}

.joker.active h3{
    color:#AFD2EA;
    margin-bottom:0;
}

table td.actions{
    position: relative;
}

a.sort-up, a.sort-down{
    /*    width: 9px;
        height: 9px;*/
    /*display: block;*/
    /*position: absolute;*/
    /*right: 40px;*/
}

a.sort-up{
    background: url(/assets/images/up.gif) no-repeat 0% 0px;
    top: 3px;
}

a.sort-down{
    background: url(/assets/images/down.gif) no-repeat 0% 0px;
    bottom: 3px;
}

#liste-offres h1{
}

#liste-offres .filtre{
    position: relative;
    float: right;
}

#liste-offres .filtre *{
    display: inline;
}

#liste-offres .filtre label{
    position: relative;
    top:-4px;
    color:#818E95;
}

#themes-list td{
    vertical-align:middle;
}
#themes-list .active{
    color:#222;
}
#themes-list .active .action .btn-success{
    display:none;
}
#themes-list .inactive .action .btn-warning{
    display:none;
}

#themes-list .action .btn-success,
#themes-list .action .btn-warning{
    margin-right:15px;
}
#themes-list .action img{
    position: relative;
    top:5px;
}


#contenu.themes table td ul {
  margin: 0 0 5px;
  font-size: 14px;
  font-style: italic;
  color: #f00;
}
#contenu.themes table td ul li {
  list-style-type: none;
}
#contenu.themes table.multi td {
  width: 230px;
}
#contenu.themes .block-images ul {
  text-align: center;
}
#contenu.themes .legend {
  font-size: 11px;
}
#contenu.themes .img_name {
  font-size: 14px;
}
#contenu.themes .img_size {
  font-size: 12px;
  color: #888;
}
#contenu.themes .to-replacing {
  opacity: 0.5;
}


#contenu table.multi td{
    width:120px;
}
#contenu table.multi td:last-child{
    width:auto;
}
#contenu table.multi td.label_formulaire{
    width:29.7%;
}
#contenu table.multi td.label_formulaire2{
    width:200px;
}

#contenu #theme_marge, #contenu #theme_dateDebut, #contenu #theme_dateFin{
    width:90px;
}

#themes-list td.text-center{
    text-align: center;
}

.theme-name img{
    margin-bottom: 10px;
}

.bg-images-container{
   padding-left: 30%;
   width: 95%;
   box-sizing:border-box;
   margin-top:10px;
   margin-bottom:30px;
}
.bg-images-container .image-block{
    border:1px solid rgba(82, 168, 236, 0.6);
    padding: 5px;
    text-align: center;
}
.bg-images-container .image-block img{
    width:100%;
}

.image{
    cursor: pointer;
}

.pipette-color {
  font-size: 12px;
  margin: 3px 0 0 40px;
}
#img-canvas {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  background: rgba(0,0,0,0.5);
  text-align: center;
  padding-top: 15%;
}
#img-canvas .container-canvas {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid gray;
  display: inline-block;
}
#img-canvas .container-canvas canvas {
}
#img-canvas .container-canvas canvas:hover {
  cursor: crosshair;
}
#img-canvas .container-canvas a {
  display: block;
}

/*
color picker
*/

.colorSelector {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    background: url(colorpicker/select2.png);
}
.colorSelector div {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 28px;
    height: 28px;
    background: url(colorpicker/select2.png) center;
}
.colorpickerHolder {
    top: 32px;
    left: 0;
    width: 356px;
    height: 0;
    overflow: hidden;
    position: absolute;
    z-index: 100;
}
.colorpickerHolder .colorpicker {
    background-image: url(colorpicker/custom_background.png);
    position: absolute;
    bottom: 0;
    left: 0;
}
.colorpickerHolder .colorpicker_hue div {
    background-image: url(colorpicker/custom_indic.gif);
}
.colorpickerHolder .colorpicker_hex {
    background-image: url(colorpicker/custom_hex.png);
}
.colorpickerHolder .colorpicker_rgb_r {
    background-image: url(colorpicker/custom_rgb_r.png);
}
.colorpickerHolder .colorpicker_rgb_g {
    background-image: url(colorpicker/custom_rgb_g.png);
}
.colorpickerHolder .colorpicker_rgb_b {
    background-image: url(colorpicker/custom_rgb_b.png);
}
.colorpickerHolder .colorpicker_hsb_s {
    background-image: url(colorpicker/custom_hsb_s.png);
    display: none;
}
.colorpickerHolder .colorpicker_hsb_h {
    background-image: url(colorpicker/custom_hsb_h.png);
    display: none;
}
.colorpickerHolder .colorpicker_hsb_b {
    background-image: url(colorpicker/custom_hsb_b.png);
    display: none;
}
.colorpickerHolder .colorpicker_submit {
    background-image: url(colorpicker/custom_submit.png);
}
.colorpickerHolder .colorpicker input {
    color: #778398;
}
div.color-picker {
    position: relative;
    height: 36px;
    margin-bottom: 10px;
}

/* Pages erreur */
.page_error footer {
  display: none;
}

#message_erreur{
  margin: 60px auto 30px;
  width: 67%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: #3b3b3b;
}
.error_text{
  font-size: 2.5em;
  margin-bottom: 0;
}
.button-error{
  text-align: right;
  margin-right: 35px;
}
.button-error-500{
  margin-right: 0;
  text-align: right;
}
.button-error-403{
  text-align: right;
  margin-right: 15px;
}
.button_back{
  font-size: 1.6em;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #3b3b3b;
}
.button_back:hover{
  font-size: 1.7em;
}
.numero_error{
  font-size: 12em;
  line-height: 0.7em;
  margin-left: 10px;
  font-weight: 800;
  margin-bottom: 22px;
}
.error_text_find{
  font-size: 2em;
  margin-bottom: 20px;
}
.error_text_find_500{
  font-size: 2em;
  margin-bottom: 20px;
  text-align: right;
}
.fa-arrow-left:before {
  position: relative;
  right: 13px;
}
.page_error .navbar-inner {
  display: none;
}
.page_error #header {
  margin-top: 0;
}
.fa{
  width: 50px;
  text-align: right;
}
